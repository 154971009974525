import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Position } from '@capacitor/geolocation';

import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

import { Coordinate, Visit } from '@housekeep/infra';

import { AreasService } from 'services/areas-service';
import { LocationService } from 'services/location-service';

export const NEARBY_PROPERTY_RADIUS = 50;

@Component({
  selector: 'hk-location-check',
  templateUrl: './hk-location-check.component.html'
})
export class HkLocationCheck {
  @Input() visit: Visit;

  @Output() onTrack = new EventEmitter<string>();
  @Output() onSuccess = new EventEmitter<boolean>();

  public loading = true;
  public location: Coordinate;
  public locationEnabled = false;
  public isNearProperty = false;

  private locationInterval: ReturnType<typeof setInterval>;

  constructor(protected areaService: AreasService, protected locationService: LocationService) {}

  public async ngOnInit(): Promise<void> {
    await this.setLocation();
    if (!this.locationEnabled) {
      this.locationInterval = setInterval(async () => {
        await this.setLocation();
        if (this.locationEnabled) {
          clearInterval(this.locationInterval);
        }
      }, 5000);
    }
    this.loading = false;
  }

  public ngOnDestroy(): void {
    clearInterval(this.locationInterval);
  }

  public async openLocationSettings(): Promise<void> {
    try {
      await this.locationService.openLocationSettings();
      this.onTrack.emit('open-location-settings');
    } catch (err) {
      // Ignore
    }
  }

  private async setLocation(): Promise<void> {
    try {
      const position: Position = await this.locationService.getLocation();
      this.location = position.coords;
      this.locationEnabled = true;

      try {
        const distanceFromProperty = await this.areaService.getDistanceFromVisit(this.visit, this.location);
        this.isNearProperty = distanceFromProperty ? distanceFromProperty < NEARBY_PROPERTY_RADIUS : false;
      } catch (error) {
        this.isNearProperty = false;
      }

      this.onSuccess.emit(this.isNearProperty);
    } catch (err) {
      this.locationEnabled = false;
    }
  }
}
