import { WhatsNewSlide } from '../../whats-new-service';

export const LATEST_VERSION = '3.22.0';

const IMAGE_BASE_PATH = `/releases/${LATEST_VERSION}/whats-new/`;

/**
 * Return an image URL for the given filename, relative to the image base path.
 * @param filename
 */
function getImageUrl(filename): string {
  return `https://app-housekeepers.housekeep.com${IMAGE_BASE_PATH}${filename}`;
}

export const LATEST_SLIDES: WhatsNewSlide[] = [
  {
    format: 'fullBlue',
    title: '',
    description: '',
    imageUri: getImageUrl('1.png'),
    imageStyles: {
      'max-width': '300px'
    }
  },
  {
    format: 'split',
    title: 'Got feedback?',
    description: `
      <p>
        When you finish a job and rate you can now leave feedback.
      </p>
    `,
    imageUri: getImageUrl('2.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Missing products?',
    description: `
      <p>
        Feedback what products need replacing so they are there for next time.
      </p>
    `,
    imageUri: getImageUrl('3.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Faulty equipment?',
    description: `
      <p>
        Feedback any equipment issues to keep things running smoothly.
      </p>
    `,
    imageUri: getImageUrl('4.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Not enough time?',
    description: `
      <p>
        Suggest more time if the job needs it - your recommendations help set expectations.
      </p>
    `,
    imageUri: getImageUrl('5.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Tell your customers',
    description: `
      <p>
        Submit your feedback and send a pre-filled message to your customer in just a tap.
      </p>
    `,
    imageUri: getImageUrl('6.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'We hope you like this update!',
    description: `
      <p>We've made some bug fixes and other improvements so that the app is easier to use.</p>
      <p>Let the team know if you have any app issues.</p>
    `,
    imageUri: getImageUrl('7.png'),
    imageStyles: {
      'max-width': '300px'
    }
  }
];
