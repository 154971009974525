import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

import { NativeMarket } from '@capacitor-community/native-market';

import { timeout } from 'rxjs/operators';

import * as semver from 'semver';

import { ApiUrlService, Deferred } from '@housekeep/infra';

import { environment } from 'environment/environment';

import { isDeviceBrowser } from 'util/device-utils';

import { AlertService } from './alert-service';

const VERSION_ENDPOINT = 'app-version/hk/';
const VERSION_ENDPOINT_TIMEOUT = 1500;

interface VersionResponse {
  /**
   * The currently published app version.
   * This is currently unused. In future, this may be used to disable certain
   * functionality until the app is live in the App Store / Play Store.
   */
  version: string;
  /**
   * The minimum supported app version.
   * If the user has a lower version than this, they will be prompted to update.
   */
  min_version: string;
  /**
   * The enforced minimum supported app version.
   * If this is set and the user has a lower version than this, they will be
   * prompted to update and will be unable to use the app until they do.
   * This is not currently returned by the API, but is reserved for future use.
   */
  enforce_min_version?: string;
}

/**
 * Indicates whether an app update is available, recommended and required.
 */
export interface VersionUpdateInfo {
  /** If `true`, a new version is available in the App Store / Play Store. */
  updateAvailable: boolean;
  /** If `true`, the user should be prompted to update. */
  updateRecommended: boolean;
  /** If `true`, the user should be required to update. */
  updateRequired: boolean;
  /** The latest version of the app. */
  latestVersion?: string;
}

const DEV_VERSION = 'dev';

@Injectable({ providedIn: 'root' })
export class VersionService {
  private version: string;
  private _readyDeferred: Deferred<null> = new Deferred();

  constructor(
    private alertService: AlertService,
    private apiUrlService: ApiUrlService,
    private appVersion: AppVersion,
    private http: HttpClient,
    private platform: Platform
  ) {
    this.initialize();
  }

  /**
   * Check if the app can be, or needs to be, to be updated.
   * Resolves to an object containing:
   *  - `updateAvailable` - true if an update is available
   *  - `updateRecommended` - true if the user should be prompted to update
   *  - `updateRequired` - true if the user must update to continue using the app
   */
  public async getAvailableUpdate(): Promise<VersionUpdateInfo> {
    await this.ready();

    if (!this.isUpdatable()) {
      return { updateAvailable: false, updateRecommended: false, updateRequired: false };
    }
    const versionData: VersionResponse = await this.getLatestVersion();
    if (this.canUpdate(versionData.enforce_min_version)) {
      return {
        updateAvailable: true,
        updateRecommended: true,
        updateRequired: true,
        latestVersion: versionData.version
      };
    } else if (this.canUpdate(versionData.min_version)) {
      return {
        updateAvailable: true,
        updateRecommended: true,
        updateRequired: false,
        latestVersion: versionData.version
      };
    } else {
      return {
        updateAvailable: this.canUpdate(versionData.version),
        updateRecommended: false,
        updateRequired: false,
        latestVersion: versionData.version
      };
    }
  }

  public async initialize(): Promise<void> {
    await this.platform.ready();

    try {
      this.version = await this.appVersion.getVersionNumber();
    } catch (err) {
      this.version = (window as any).APP_VERSION || DEV_VERSION;
    }

    try {
      this.checkForAppUpdate().then(() => {
        // Don't need to await this promise
      });
    } catch (err) {
      // Ignore
    }

    this._readyDeferred.resolve();
  }
  /**
   * Returns `true` if the `VersionService` is ready.
   */
  public ready(): Promise<void> {
    return this._readyDeferred.promise;
  }

  /*
   * Return string of current app version
   */
  public getAppVersion(): string {
    if (!this.version) {
      throw 'No app version set';
    }
    return this.version;
  }
  /*
   * Open the platform specific app store
   */
  public async openStore(): Promise<void> {
    if (this.platform.is('ios')) {
      await NativeMarket.openStoreListing({ appId: environment.APPLE_APP_ID });
    } else {
      await NativeMarket.openStoreListing({ appId: environment.PACKAGE_NAME });
    }
  }

  /**
   * Prompt the user to update using an alert.
   * @param force If `true`, don't allow the user to cancel the alert
   * and don't close it after tapping "Update".
   */
  public async raiseUpdateAlert(force = false): Promise<void> {
    const cancelButton = { text: 'Later', role: 'cancel' };
    const updateButton = {
      text: 'Update',
      handler: () => {
        this.openStore().then();
        return !force;
      }
    };
    const buttons = force ? [updateButton] : [cancelButton, updateButton];
    const message = force
      ? 'There is a new version of the Housekeep app! Please update now.'
      : 'There is a new version of the Housekeep app! For the best experience please click Update.';
    const alert = await this.alertService.create({
      trackingName: 'Update app',
      header: `Please update your app!`,
      subHeader: message,
      backdropDismiss: !force,
      buttons
    });
    await alert.present();
  }
  /**
   * Returns `true` if the platform is 'android' or 'ios'
   * and the app is not running in the browser or in dev mode.
   */
  private isUpdatable(): boolean {
    return (
      this.version !== 'dev' &&
      !isDeviceBrowser(this.platform) &&
      (this.platform.is('android') || this.platform.is('ios'))
    );
  }
  /*
   * Retrieve latest published version from remote
   */
  private getLatestVersion(): Promise<VersionResponse> {
    /* this.platform.platforms() returns several properties (e.g. mobile, iphone, ios),
     * only one of which will be the operating system.
     */
    const osOptions = ['android', 'ios'];
    const operatingSystem: string = this.platform.platforms().filter(platform => osOptions.includes(platform))[0];
    const params = { platform: operatingSystem ? operatingSystem : '' };
    return this.http
      .get<VersionResponse>(this.apiUrlService.normaliseUrl(VERSION_ENDPOINT), { params })
      .pipe(timeout(VERSION_ENDPOINT_TIMEOUT))
      .toPromise()
      .catch();
  }
  /**
   * Returns `true` if the currently installed app version is less
   * than the given minimum version.
   */
  private canUpdate(minVersion: string): boolean {
    if (!minVersion) {
      return false;
    }

    try {
      const currentVersion = this.version.split('-')[0];
      return semver.gt(minVersion, currentVersion);
    } catch (e) {
      return false;
    }
  }
  private async checkForAppUpdate(): Promise<void> {
    const updateInfo = await this.getAvailableUpdate();
    if (updateInfo.updateRecommended) {
      this.raiseUpdateAlert(updateInfo.updateRequired);
    }
  }
}

export { VERSION_ENDPOINT };
