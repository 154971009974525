import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Platform } from '@ionic/angular';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { LocalNotifications } from '@capacitor/local-notifications';
import { Channel } from '@capacitor/local-notifications/dist/esm/definitions';

import { FirebaseMessaging, Notification } from '@capacitor-firebase/messaging';

import { nowNaive } from '@housekeep/infra';

import { AlertService } from './alert-service';
import { AnalyticsService } from './analytics-service';
import { AppContextService, LaunchContext } from './app-context-service';
import { AuthService } from './auth-service';
import { DataPush, DataPushService } from './data-push-service';
import { DeviceService } from './device-service';
import { InAppBrowserConfigService } from './in-app-browser-config-service';
import { RequestService } from './request-service';
import { DEVICE_FCM_TOKEN, NOTIFICATION_PERMISSION_REQUESTED_KEY, StorageService } from './storage-service';

/**
 * The target used for deeplinking.
 */
enum UrlTarget {
  /** Open the URL in the app webview. */
  App = 'app',
  /** Open the URL in an in-app browser. */
  InAppBrowser = 'iab',
  /** Open the URL in the system browser. */
  System = 'system'
}

// Open URLs in the app webview by default, if there is no `to_url_target` in the push notification data. */
const URL_TARGET_DEFAULT = UrlTarget.App;

/**
 * A push containing a unique ID allowing the state ("delivered" or "opened") to be reported to the backend.
 */
export interface TrackablePush extends Notification {
  data: {
    /**
     * An ID for the message, used to update the contact record in the backend.
     */
    delivery_id: string;
  };
}

/**
 * Push notification data.
 */
export interface PushNotification extends Notification {
  data: {
    /**
     * An optional ID for the message, used to update the contact record in the backend.
     */
    delivery_id?: string;
    /**
     * An icon to be used for local notifications - this duplicates the icon defined elsewhere in the Firebase
     * message payload, as the app only has access to limited fields of the payload. Used for Android only.
     */
    icon?: string;
    /**
     * If "true", display the notification even if it is received while the app is in the foreground.
     * Used for Android only: foreground notifications are always displayed on iOS.
     */
    notification_foreground?: 'false' | 'true';
  };
}

/**
 * A push notification that includes a `to_url` property for deeplinking.
 */
interface DeeplinkPushNotification extends PushNotification {
  data: PushNotification['data'] & {
    to_url: string;
    to_url_target?: UrlTarget;
    hash: string;
  };
}

/**
 * A push notification that includes a `to_view` property for deeplinking.
 * @deprecated
 */
interface LegacyPushNotification extends PushNotification {
  data: PushNotification['data'] & {
    to_view: string;
  };
}

/**
 * Type guard for trackable pushes.
 */
function isTrackable(pushNotification: Notification): pushNotification is TrackablePush {
  return (pushNotification.data as Record<string, string>).delivery_id !== undefined;
}

/**
 * Type guard for push notifications.
 */
function isNotification(pushNotification: Notification): pushNotification is PushNotification {
  return pushNotification.body !== undefined;
}

/**
 * Type guard for data pushes.
 */
function isData(pushNotification: Notification): pushNotification is DataPush {
  return 'action' in (pushNotification.data as Record<string, string>);
}

/**
 * Type guard for `DeeplinkPushNotification`
 */
function hasDeeplink(pushNotification: PushNotification): pushNotification is DeeplinkPushNotification {
  return 'to_url' in pushNotification.data;
}

/**
 * Type guard for `LegacyPushNotification`
 */
function isLegacy(pushNotification: PushNotification): pushNotification is LegacyPushNotification {
  return 'to_view' in pushNotification.data;
}

/**
 * The status of a push notification
 * (mapping to the possible values of `ContactRecord.status` in the backend).
 */
enum PushNotificationState {
  Delivered = 'd',
  Opened = 'o'
}

// See `ContactRecord.mechanism` in the backend
const CONTACT_RECORD_PUSH_MECHANISM = 'push';

/**
 * Mappings from page class name to URL, for use in the `to_view` property
 * of push notifications. This is now deprecated: `to_url` should be used instead.
 * @deprecated
 * @todo cut in #184638277
 */
const VIEW_REDIRECTS = {
  BadgePage: '/app-housekeepers/id',
  ChatToHousekeepPage: '/app-housekeepers/help/chat-to-housekeep',
  ClosedHelpRequestsPage: '/app-housekeepers/help/closed-help-requests',
  ContactHousekeepPage: '/app-housekeepers/help/contact',
  CustomerCommsPage: '/app-housekeepers/customer-comms',
  ExtraJobsPage: '/app-housekeepers/extra-jobs',
  HelpPage: '/app-housekeepers/help',
  HelpRequestActivityPage: '/app-housekeepers/help-request-activity',
  HistoryPage: '/app-housekeepers/history',
  LegalPage: '/app-housekeepers/legal',
  NewsPage: '/app-housekeepers/news',
  OpenHelpRequestsPage: '/app-housekeepers/help/open-help-requests',
  PerformancePage: '/app-housekeepers/performance',
  ProfilePage: '/app-housekeepers/profile',
  ProfileContactDetailsPage: '/app-housekeepers/contact-details',
  ProfileCustomerKeys: '/app-housekeepers/customer-keys',
  ProfileHoursPage: '/app-housekeepers/hours',
  ProfilePaymentDetailsPage: '/app-housekeepers/payment-details',
  ProfilePostcodesPage: '/app-housekeepers/postcodes',
  ProfileReferralsPage: '/app-housekeepers/refer',
  ProfileTimeOffPage: '/app-housekeepers/time-off',
  RatingsPage: '/app-housekeepers/performance',
  SchedulePage: '/app-housekeepers/schedule',
  ScheduleListPage: '/app-housekeepers/schedule-list',
  WhatsNewPage: '/app-housekeepers/whats-new'
};

const CHANNEL_OPTS = { name: 'Housekeep Professionals', importance: 4, vibration: true, visibility: 1 };
const LOCAL_CHANNEL_ID = 'housekeep_popup';
const FIREBASE_CHANNEL_ID = 'housekeep_firebase_popup';

/**
 * As of version 3.0.0, the `to_view` property is deprecated, `to_view_alt` is no longer supported, and `nav_params`
 * are not supported. Instead, use `to_url`.
 */
@Injectable({ providedIn: 'root' })
class NotificationService {
  private isSubscribed = false;

  constructor(
    private alertService: AlertService,
    private analyticsService: AnalyticsService,
    private appContextService: AppContextService,
    private authService: AuthService,
    private dataPushService: DataPushService,
    private device: DeviceService,
    private iab: InAppBrowser,
    private inAppBrowserConfigService: InAppBrowserConfigService,
    private platform: Platform,
    private requestService: RequestService,
    private router: Router,
    private storageService: StorageService
  ) {}

  public async initialize(): Promise<void> {
    if (!this.platform.is('cordova')) {
      return;
    }

    if (this.platform.is('android')) {
      await LocalNotifications.createChannel({
        id: LOCAL_CHANNEL_ID,
        ...CHANNEL_OPTS
      } as Channel);

      await FirebaseMessaging.createChannel({
        id: FIREBASE_CHANNEL_ID,
        ...CHANNEL_OPTS
      });
    }

    this.registerFirebaseToken();
    this.subscribeNotificationsReceived();
  }

  /**
   * Check if the app has already requested notification permission.
   * @return {Promise<boolean>}
   */
  public async hasRequestedPermission(): Promise<boolean> {
    return !!(await this.storageService.get(NOTIFICATION_PERMISSION_REQUESTED_KEY));
  }

  /**
   * Check if the app has permission to receive push notifications.
   * @return {Promise<boolean>}
   */
  public async hasPermission(): Promise<boolean> {
    return (await FirebaseMessaging.checkPermissions()).receive === 'granted';
  }

  /**
   * Requests notification permission if the app has not already requested it.
   *
   * @param {boolean} withAlert If `true`, show a Housekeep pre-permission alert
   * @return {Promise<any>}
   */
  public async requestPermission(withAlert = false): Promise<void> {
    let notificationPermissionSet: boolean;
    try {
      notificationPermissionSet = (await this.hasRequestedPermission()) || (await this.hasPermission());
    } catch (err) {
      notificationPermissionSet = true;
    }

    try {
      if (!notificationPermissionSet) {
        if (withAlert) {
          await this.showPermissionAlert();
        } else {
          await this._requestPermission();
        }
      }
    } catch (error) {
      // Ignore
    }
  }

  /**
   * Retrieve the current CM token, if any, from storage.
   * @return {Promise<string>} A Promise resolving to the FCM token
   */
  public getCurrentFCMToken(): Promise<string> {
    return this.storageService.get(DEVICE_FCM_TOKEN);
  }

  /**
   * Unregister current token with Firebase
   */
  public async cleanFirebaseToken(): Promise<void> {
    if (this.platform.is('cordova')) {
      this.isSubscribed = false;
      await Promise.all([
        FirebaseMessaging.deleteToken(),
        FirebaseMessaging.removeAllListeners(),
        LocalNotifications.removeAllListeners()
      ]);
    }
  }

  /**
   * Show a Housekeep alert before requesting notification permission.
   */
  private async showPermissionAlert(): Promise<void> {
    const alert = await this.alertService.create({
      trackingName: 'Receive notifications',
      header: 'Receive notifications',
      subHeader: `Housekeep will send you notifications to send you information such
        as changes to jobs. Please enable this when asked.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'OK',
          handler: () => {
            this._requestPermission();
          }
        }
      ]
    });

    await alert.present();
  }

  /*
   * Request an FCM token from FirebaseHkWrapper and save it
   */
  private async registerFirebaseToken(): Promise<void> {
    this.subscribeTokenRefresh();
    await FirebaseMessaging.getToken();
  }

  /*
   * Subscribe an observable to listen for a new token and save
   */
  private subscribeTokenRefresh(): void {
    FirebaseMessaging.addListener('tokenReceived', async event => {
      await this.setNewFCMToken(event.token);
    });
  }

  /*
   * Subscribe to received notifications, if not already subscribed.
   */
  private subscribeNotificationsReceived(): void {
    if (this.isSubscribed) {
      return;
    }

    // Subscribe to notifications received in the foreground and data messages received in the background
    FirebaseMessaging.addListener('notificationReceived', async ({ notification }) => {
      if (this.authService.isAuthenticated) {
        if (isTrackable(notification)) {
          this.registerReceived(notification.data.delivery_id, PushNotificationState.Delivered);
        }

        if (isNotification(notification)) {
          await this.receiveForeground(notification);
        }

        // A push notification can also contain actionable data, or the notification can be data-only: in either case,
        // pass it to the DataPushService to handle.
        if (isData(notification)) {
          this.dataPushService.onDataPushReceived(notification, () => {
            if (isTrackable(notification)) {
              this.registerReceived(notification.data.delivery_id, PushNotificationState.Opened);
            }
          });
        }
      }
    });

    // Subscribe to notifications received in the background and tapped by the user
    FirebaseMessaging.addListener('notificationActionPerformed', async ({ notification }) => {
      if (this.authService.isAuthenticated) {
        this.appContextService.setLaunchContext(LaunchContext.PushNotification, {
          deliveryId: (notification as PushNotification).data.delivery_id
        });
        await this.notificationTapped(notification as PushNotification);

        // Handle data only for tapped background notifications. If the notification was received in the foreground,
        // this will have been done already by the notificationReceived listener.
        if (isData(notification)) {
          this.dataPushService.onDataPushReceived(notification);
        }
      }
    });

    // On Android, remote notifications are not automatically displayed if received when the app is in the foreground.
    // Instead, we handle them and display a local notification and subscribe to local notifications tapped by the user
    if (this.platform.is('android')) {
      LocalNotifications.addListener('localNotificationActionPerformed', async ({ notification }) => {
        if (this.authService.isAuthenticated) {
          return this.notificationTapped({
            body: notification.body,
            data: notification.extra,
            title: notification.title
          } as PushNotification);
        }
      });
    }

    this.isSubscribed = true;
  }

  /*
   * Handle a Notification received in the foreground.
   * This happens when the user has the Application open.
   */
  private async receiveForeground(notification: PushNotification): Promise<void> {
    if (notification.data.notification_foreground === 'true' && this.platform.is('android')) {
      await LocalNotifications.schedule({
        notifications: [
          {
            body: notification.body,
            channelId: LOCAL_CHANNEL_ID,
            extra: notification.data,
            id: nowNaive().unix(),
            smallIcon: notification.data.icon,
            title: notification.title
          }
        ]
      });
    }
  }

  /*
   * Handle a Notification that has been explicitly tapped,
   * whether it was received in the foreground or background.
   */
  private async notificationTapped(notification: PushNotification): Promise<void> {
    this.analyticsService.trackPushOpen(notification).then();

    if (isTrackable(notification)) {
      this.registerReceived(notification.data.delivery_id, PushNotificationState.Opened);
    }

    if (hasDeeplink(notification)) {
      const target = notification.data.to_url_target || URL_TARGET_DEFAULT;

      if (target === UrlTarget.App) {
        await this.router.navigateByUrl(
          this.router.createUrlTree([notification.data.to_url], {
            fragment: notification.data.hash ? notification.data.hash : ''
          })
        );
      } else {
        this.inAppBrowserConfigService.configureBrowser(
          this.iab,
          notification.data.to_url,
          target === UrlTarget.System ? '_system' : '_blank'
        );
      }
    } else if (isLegacy(notification)) {
      await this.redirectView(notification.data.to_view);
    }
  }

  /*
   * Callback to register push as received on remote
   */
  private registerReceived(deliveryId: string, state: string): void {
    const data = {
      id: deliveryId,
      type: CONTACT_RECORD_PUSH_MECHANISM,
      state: state
    };
    this.requestService.post(`contact/delivery/`, data);
  }

  /*
   * Redirects the navigation to the page name stipulated.
   * If the viewName is defined in `VIEW_REDIRECTS`, that page will be used.
   * Otherwise, we'll assume the viewName is actually the URL of a page.
   */
  private async redirectView(viewName: string): Promise<any> {
    const url = VIEW_REDIRECTS[viewName] || viewName;
    await this.router.navigateByUrl(url);
  }

  /*
   * Save new token locally and update remote
   */
  private async setNewFCMToken(token: string): Promise<void> {
    const storedToken = await this.getCurrentFCMToken();
    if (storedToken === token) {
      return;
    }

    const deviceExternalId = await this.device.performDeviceActions();
    if (deviceExternalId) {
      const data = { fcm_token: token };
      this.requestService.patch(`device/${deviceExternalId}/update/`, data).then();
      this.storageService.set(DEVICE_FCM_TOKEN, token).then();
    }
  }

  /*
   * Prompt OS for notification permissions
   */
  private _requestPermission() {
    this.storageService.set(NOTIFICATION_PERMISSION_REQUESTED_KEY, true);
    return FirebaseMessaging.requestPermissions();
  }
}

export { NotificationService };
