import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { dateToStr, Visit } from '@housekeep/infra';

import { TimeService } from 'services/time-service';

/**
 * Displays a visit block within the schedule.
 */
@Component({
  selector: 'visit-block',
  templateUrl: './visit-block.component.html',
  styleUrls: ['./visit-block.component.scss']
})
export class VisitBlock {
  @Input() hasChanges: boolean;
  @Input() visit: Visit;
  @Input() visitNumber: number;
  @Input() navigationExtras: NavigationExtras = {};

  @Output() visitClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router, private timeService: TimeService) {}

  /**
   * Returns the appropriate start time slot as a nicely formatted string.
   */
  public get getStartTimeDisplayValue(): string {
    if (this.visit.recommendedStartTimeSlot) {
      return this.visit.recommendedStartTimeSlot.startTimeDisplay({ includeSharp: false });
    }
    return this.visit.timeSlot.startTimeDisplay({ includeSharp: false });
  }

  /*
   * Modify customer name to make suitable for app display
   */
  public getDisplayName(customerName: string): string {
    const MAX_LENGTH = 15;
    const ELLIPSIS = '...';
    customerName = customerName.split('@')[0]; // Email provider not relevant
    if (customerName.length < MAX_LENGTH) {
      return customerName;
    }

    const customerNames = customerName.split(' ').filter(Boolean);
    let displayName = '';
    for (const name of customerNames) {
      displayName = displayName.concat(name);
      if (displayName.length === MAX_LENGTH) {
        return displayName;
      } else if (displayName.length > MAX_LENGTH) {
        return displayName.substring(0, MAX_LENGTH - ELLIPSIS.length).concat(ELLIPSIS);
      }
      displayName = displayName.concat(' ');
    }
    return customerName;
  }

  public async goToVisit(visit): Promise<void> {
    await this.router.navigate(
      ['/app-housekeepers/schedule/visit', visit.jobId, dateToStr(visit.scheduledDate), dateToStr(visit.actualDate)],
      this.navigationExtras
    );
    this.visitClicked.emit();
  }

  public visitIsToday(): boolean {
    return this.visit.actualDate.isSame(this.timeService.today());
  }
}
