import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'guards/auth.guard';

// TODO create auth module
const unauthenticatedRoutes: Routes = [
  {
    path: 'app-housekeepers/login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'app-housekeepers/reset-password/:token',
    loadChildren: () =>
      import('./pages/reset-password-finish/reset-password-finish.module').then(m => m.ResetPasswordFinishPageModule)
  },
  {
    path: 'app-housekeepers/reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'app-housekeepers/onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
  },
  {
    path: 'app-housekeepers/get-started/:applicantId/:token',
    redirectTo: '/app-housekeepers/onboarding/welcome/:applicantId/:token'
  }
];

const authenticatedRoutes: Routes = [
  {
    path: 'app-housekeepers/id',
    loadChildren: () => import('./pages/badge/badge.module').then(m => m.BadgePageModule)
  },
  {
    path: 'app-housekeepers/cleaning-guides',
    loadChildren: () => import('./pages/cleaning-guides/cleaning-guides.module').then(m => m.CleaningGuidesPageModule)
  },
  {
    path: 'app-housekeepers/help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'app-housekeepers/customer-comms',
    loadChildren: () => import('./pages/customer-comms/customer-comms.module').then(m => m.CustomerCommsPageModule)
  },
  {
    path: 'app-housekeepers/extra-jobs',
    loadChildren: () => import('./pages/extra-jobs/extra-jobs.module').then(m => m.ExtraJobsPageModule)
  },
  {
    // TODO move this to the history module
    path: 'app-housekeepers/payments/period/:periodId',
    loadChildren: () => import('./pages/history-period/history-period.module').then(m => m.HistoryPeriodPageModule)
  },
  {
    path: 'app-housekeepers/payments',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'app-housekeepers/legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule)
  },
  {
    path: 'app-housekeepers/news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'app-housekeepers/performance',
    loadChildren: () => import('./pages/performance/performance.module').then(m => m.PerformancePageModule)
  },
  {
    path: 'app-housekeepers/permissions',
    loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsPageModule)
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/add-hours',
    loadChildren: () =>
      import('./pages/profile-add-hours/profile-add-hours.module').then(m => m.ProfileAddHoursPageModule)
  },
  {
    path: 'app-housekeepers/profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'app-housekeepers/time-off',
    loadChildren: () => import('./pages/profile-time-off/profile-time-off.module').then(m => m.ProfileTimeOffPageModule)
  },
  {
    path: 'app-housekeepers/refer',
    loadChildren: () =>
      import('./pages/profile-referrals/profile-referrals.module').then(m => m.ProfileReferralsPageModule)
  },
  {
    path: 'app-housekeepers/schedule-list',
    loadChildren: () => import('./pages/schedule-list/schedule-list.module').then(m => m.ScheduleListPageModule)
  },
  {
    path: 'app-housekeepers/schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    // TODO move this to the schedule module
    path: 'app-housekeepers/schedule/day/:date',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'app-housekeepers/schedule/show/menu',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'app-housekeepers/whats-new',
    loadChildren: () => import('./pages/whats-new/whats-new.module').then(m => m.WhatsNewPageModule)
  },
  {
    path: 'app-housekeepers/whats-next',
    loadChildren: () => import('./pages/whats-next/whats-next.module').then(m => m.WhatsNextPageModule)
  },
  {
    path: 'app-housekeepers/worker-comms',
    loadChildren: () => import('./pages/worker-comms/worker-comms.module').then(m => m.WorkerCommsPageModule)
  },
  {
    path: 'app-housekeepers/achievements',
    loadChildren: () => import('./pages/achievements/achievements.module').then(m => m.AchievementsPageModule)
  }
].map((route: Route) => ({ ...route, canActivate: [AuthGuard] }));

const legacyRedirects: Routes = [
  {
    path: 'reliability',
    redirectTo: 'app-housekeepers/performance/reliability',
    pathMatch: 'full'
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app-housekeepers/schedule',
    pathMatch: 'full'
  },
  {
    path: 'app-housekeepers',
    redirectTo: 'app-housekeepers/schedule',
    pathMatch: 'full'
  },
  ...unauthenticatedRoutes,
  ...authenticatedRoutes,
  ...legacyRedirects,
  {
    path: '**',
    loadChildren: () => import('./pages/error/page-not-found.module').then(m => m.PageNotFoundPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
