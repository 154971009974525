import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

// Cordova plugin wrappers
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SecureStorage } from '@awesome-cordova-plugins/secure-storage/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import * as SentryAngular from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { LottieModule } from 'ngx-lottie';

// Housekeep modules
import { HkChatClientModule, TwilioAuthService, ZendeskAuthService } from '@housekeep/hk-chat-client';
import { HkInfraModule } from '@housekeep/infra';

import { HousekeeperAppComponentsModule } from 'components/components.module';

import { HousekeeperAppDirectivesModule } from 'directives/directives.module';

import { environment, isDevEnv, isStagingEnv } from 'environment/environment';

// Pipes
import { HousekeeperAppPipesModule } from 'pipes/pipes.module';

// Services
import { APP_CONFIG, INJECTED_APP_CONFIG } from 'services/app-config';
import { AppTwilioAuthService, AppZendeskAuthService } from 'services/chat-auth-service';

// Util
import { HkErrorHandler, sentryBeforeSend } from 'util/error';

// Environment
import { environment as ngEnvironment } from '../environments/environment';
// Routing
import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';

const sentryReleaseNumber = (window as any).APP_VERSION || 'dev';

Sentry.init(
  {
    beforeSend: sentryBeforeSend,
    debug: isDevEnv || isStagingEnv,
    dsn: environment.SENTRY_DSN,
    environment: environment.ID,
    release: `app-housekeepers-${sentryReleaseNumber}`,
    ignoreErrors: ['Object Not Found Matching Id']
  },
  SentryAngular.init
);

// Needed as a separate function for the AOT compiler.
// Only import lottie-web when needed by the page
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HkChatClientModule,
    HttpClientModule,
    HousekeeperAppComponentsModule,
    HousekeeperAppDirectivesModule,
    HousekeeperAppPipesModule,
    HkInfraModule.forRoot({ API_ROOT: environment.API_ROOT }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: ngEnvironment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LottieModule.forRoot({ player: playerFactory }),
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    AppVersion,
    Badge,
    Device,
    InAppBrowser,
    SecureStorage,
    SocialSharing,
    { provide: ErrorHandler, useClass: HkErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: TwilioAuthService, useClass: AppTwilioAuthService },
    { provide: ZendeskAuthService, useClass: AppZendeskAuthService },
    { provide: APP_CONFIG, useValue: INJECTED_APP_CONFIG }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
