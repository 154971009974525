<p>Booked arrival time is {{ visit.timeSlot.startTimeDisplay() }}</p>
<p
  *ngIf="(isOnTime && showOnTime) || !isOnTime"
  class="strong mar-t-10"
  [class.text--success]="isOnTime"
  [class.text--warning]="!isOnTime"
>
  <hk-wc-icon
    [name]="isOnTime ? 'tick-circle' : 'exclamation-mark-circle'"
    size="20"
    class="mar-r-5"
  ></hk-wc-icon>
  <ng-container *ngIf="isOnTime">&nbsp;You are on time</ng-container>
  <!-- prettier-ignore -->
  <ng-container *ngIf="!isOnTime">&nbsp;You are {{ arrivalDifferenceDisplay }}</ng-container>
</p>
