import { Component, Input } from '@angular/core';

import { Duration, humanReadableDurationString, toTime, Visit } from '@housekeep/infra';

import { TimeService } from 'services/time-service';

@Component({
  selector: 'hk-arrival-time',
  templateUrl: './hk-arrival-time.component.html'
})
export class HkArrivalTime {
  @Input() visit: Visit;
  @Input() showOnTime: boolean = true;

  public arrivalDifferenceDisplay: string;
  public isOnTime = false;

  public constructor(protected timeService: TimeService) {}

  public ngOnInit(): void {
    const now = toTime(this.timeService.now());
    this.isOnTime = this.visit.timeSlot.start.isBefore(now) && this.visit.timeSlot.end.isAfter(now);

    if (!this.isOnTime) {
      let timeDifference: Duration;
      if (this.visit.timeSlot.start.isAfter(now)) {
        timeDifference = new Duration({
          minutes: this.visit.timeSlot.start.diff(now, 'minutes')
        });
        this.arrivalDifferenceDisplay = `${humanReadableDurationString(timeDifference)} early`;
      } else {
        timeDifference = new Duration({
          minutes: now.diff(this.visit.timeSlot.end, 'minutes')
        });
        this.arrivalDifferenceDisplay = `${humanReadableDurationString(timeDifference)} late`;
      }
    }
  }
}
