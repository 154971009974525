import { area, job, postcode, property, task, timeSlot, todayNaive, toTime } from '@housekeep/infra';

import { assignableJob, AssignableJob } from 'models/assignable-job';

// Finishing a visit early
export const BILLING_PERIOD_HOUR_INCREMENTS = 0.5;
export const EARLY_GRACE_PERIOD_MINUTES = 15;
export const MINIMUM_BILLABLE_HOURS = 2;

// Finishing a visit late
export const LATE_GRACE_PERIOD_MINUTES = 25;

export const MOCKED_EXTRA_REGULAR_JOB: AssignableJob = assignableJob.create({
  job: job.create({
    nextVisitDate: todayNaive(),
    tasks: [task.create({ hours: 2, type: 'cleaning' })],
    timeSlot: timeSlot.create({
      day: todayNaive(),
      start: toTime('09:00:00'),
      end: toTime('10:00:00')
    }),
    frequency: 7,
    property: property.create({
      postcode: postcode.create({
        outward: 'EC1R',
        inward: '3BU',
        area: area.create({ localDisplayName: 'London' })
      })
    })
  })
});
