import { model, Model, Visit } from '@housekeep/infra';

export enum CleaningEquipmentProducts {
  BathroomCleaner = 'bathroom_cleaner',
  BinBags = 'bin_bags',
  FloorCleaner = 'floor_cleaner',
  Gloves = 'gloves',
  Iron = 'iron',
  KitchenCleaner = 'kitchen_cleaner',
  LimescaleRemover = 'limescale_remover',
  MicrofibreCloth = 'microfibre_cloth',
  Mop = 'mop',
  MultiSurfaceCleaner = 'multi_surface_cleaner',
  Sponge = 'sponge',
  Vacuum = 'vacuum',
  VacuumBags = 'vacuum_bags',
  WindowCleaner = 'window_cleaner'
}

export enum WorkerVisitRatingChoice {
  Bad = 1,
  Okay = 3,
  Good = 5
}

export enum WorkerRatingIssue {
  Access = 'access',
  CleaningEquipment = 'cleaning_equipment',
  CleaningProducts = 'cleaning_products',
  CustomerAttitude = 'customer_attitude',
  JobLocation = 'job_location',
  NeedsDeepClean = 'needs_deep_clean',
  NeedsEotClean = 'needs_eot_clean',
  NotEnoughTime = 'not_enough_time'
}

export interface WorkerVisitRatingIssue extends Model {
  /** If the housekeeper brought cleaning products. */
  broughtCleaningProducts: boolean;
  /** The key for the issue. */
  issue: WorkerRatingIssue;
  /** The human-readable name for the issue */
  issueDisplay: string;
  /** If the housekeeper let the customer know about the issue. */
  letCustomerKnow: boolean;
  /** The ratings for which the issue applies. */
  ratings: WorkerVisitRatingChoice[];
}

export const workerVisitRatingIssue: WorkerVisitRatingIssue = model.extend({
  modelName: 'workerVisitRatingIssue'
});

export interface WorkerVisitRating extends Model {
  additionalNotes?: string;
  blockCustomer: boolean;
  equipmentProductsToCheck?: CleaningEquipmentProducts[];
  issues: WorkerVisitRatingIssue[];
  moreInfo: string;
  moreTimeNeededMinutes?: number;
  rating: WorkerVisitRatingChoice;
  visit?: Visit;
}

export const workerVisitRating: WorkerVisitRating = model.extend({
  modelName: 'workerVisitRating'
});
