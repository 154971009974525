<ng-container *ngIf="!loading; else skeletonText">
  <ng-container *ngIf="locationEnabled; else locationDisabled">
    <p *ngIf="isNearProperty; else farFromProperty" class="strong mar-y-15 text--success">
      <hk-wc-icon name="tick-circle" size="20" class="mar-r-5"></hk-wc-icon>
      You are close to the property
    </p>
    <ng-template #farFromProperty>
      <p class="strong mar-y-15 text--warning">
        <hk-wc-icon name="exclamation-mark-circle" size="20" class="mar-r-5"></hk-wc-icon>
        You are a bit far away
      </p>
    </ng-template>
    <hk-check-directions [property]="visit.property"></hk-check-directions>
  </ng-container>
  <ng-template #locationDisabled>
    <p class="strong mar-y-15 text--danger">
      <hk-wc-icon name="exclamation-mark-circle" size="20" class="mar-r-5"></hk-wc-icon>
      Location services are off
    </p>
    <ion-button expand="block" (click)="openLocationSettings()">
      <hk-wc-icon name="navigation-arrow" size="24" slot="start"></hk-wc-icon>
      Enable location services
    </ion-button>
  </ng-template>
</ng-container>

<ng-template #skeletonText>
  <ion-skeleton-text class="mar-t-15" animated></ion-skeleton-text>
</ng-template>
